<template>
  <div>
    <Header msg="Credit card pre-authorization request from Blue Oasis Pool Services" />
    <MainContent></MainContent>
  </div>
</template>

<script>
import MainContent from './components/MainContent.vue'
import Header from './components/Header'

export default {
  name: 'App',
  components: {
    Header,
    MainContent
  }
}
</script>

<style>
html, body {
  background-color: #0c4692;
  margin: 0px;
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
