<template>
  <div class="content-wrapper">
    <div class="payment-container">
      <CreditCard />

      <h3>Blue Oasis Pool Services, LLC</h3>
      <p>
        Blue Oasis Pools, LLC<br>
        P.O Box 111365<br>
        Carrolton TX 75011<br>
      </p>
      <ul>
        <li><a href="#" target="_blank" rel="noopener">(214) 888-2844</a></li>
        <li><a href="#" target="_blank" rel="noopener">blueoasispools1@gmail.com</a></li>
        <li><a href="#" target="_blank" rel="noopener">www.blueoasispoolservices.com</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import CreditCard from './CreditCard'

export default {
    name: 'MainContent',
    components: {
      CreditCard
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-wrapper {
  margin: 0;
  background: #F4F5F5;
  width: 100%;    
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.payment-container {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 80%;
  background: white;
  border: 1px solid #c6cbcd;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
