import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt';

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(VueAxios, axios);
app.mount('#app')
