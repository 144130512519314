<template>
  <div class="header">
    <div class="logo-container">
      <img class="logo" alt="Blue Oasis Pool Services" src="../assets/logo.png">
    </div>
    <div class="header-message">
      <h1>{{ msg }}</h1> 
    </div>
    <div class="invoice-summary" v-show="false">
        <div class="invoice-details">Invoice Amount: $160.00</div>
        <div class="invoice-details-separator"></div>
        <div class="invoice-details">Due On: {{currentDateTime()}}</div>
        <div class="invoice-details-separator"></div>
        <div class="invoice-details">Repeats: Monthly</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Header',
  props: {
    msg: String
  },
  methods: {
    currentDateTime() {
      return moment().format('MMMM Do, Y')
    }
  },
  data(){
      return {
          cardSubmitted: false,
      }
  },
  mounted() {
    this.emitter.on('card_submitted', (state) => {
      this.cardSubmitted = state;
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  border-top:  10px solid #0c4692;
  background-color: white;
  width: 100%;    
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.logo-container {
  margin-top: 15px;
  width: 100%;
}
.header-message {
  margin: 20px 0 0;
  background-color: #2DB6EF;
  width: 100%;

}

h1 {
  color: white;
  font-size: 1.2em;
}

.invoice-summary{
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    font-family: Averta,sans-serif;
    color: #1c252c;
    font-weight: normal;
    width: 100%;
    border-top: 1px solid #D0DBE1;
    border-bottom: 1px solid #D0DBE1;
}

.invoice-details{
    font-size: 16px;
    color: #6C90A2;
    display: inline-block;
    vertical-align: middle;
    background: #FFF;
    line-height: 50px;
    text-align: center;
}
.invoice-details-separator{
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: #BCBDC0;
    vertical-align: middle;
    margin: 0 10px;
    font-size: 18px;
    color: #6C90A2;
    display: inline-block;
}
</style>
